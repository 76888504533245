import { AppBar, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, InputAdornment, List, ListItem, Paper, Slide, Table, TableBody, TableCell, TableHead, TableRow, TextField, Toolbar, Tooltip, Typography } from '@material-ui/core'
import React, { Component } from 'react'
import moment from 'moment';
import { withSnackbar } from 'notistack';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import CloseIcon from '@material-ui/icons/Close';
import UnallocatedRoles from './UnallocatedRoles';
import ZoomIn from '@material-ui/icons/ZoomIn';
import ZoomOut from '@material-ui/icons/ZoomOut';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import SearchIcon from '@material-ui/icons/Search';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class StaffLocationView extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
            selectedStartDate: this.props.selectedStartDate,
            selectedEndDate: this.props.selectedEndDate,
            splittedCRM: this.props.splittedCRM,
            allMonths: this.props.allMonths,
            finalColumns: this.props.finalColumns,
            showRoleDialog: false,
            projectDetails: [],
            selectedCRM: {},
            zoom: 0.9,
            roleName: '',
        }
    }

    wc_hex_is_light(color) {
        const hex = color.replace('#', '');
        const c_r = parseInt(hex.substr(0, 2), 16);
        const c_g = parseInt(hex.substr(2, 2), 16);
        const c_b = parseInt(hex.substr(4, 2), 16);
        const brightness = ((c_r * 299) + (c_g * 587) + (c_b * 114)) / 1000;
        if (brightness > 155) {
            return '#000000'
        } else {
            return '#ffffff'

        }
    }

    componentDidMount() {
        console.log(this.props)
    }

    handleSearchChange(data) {
        console.log(data)
        const data_lowercase = data.toLowerCase()
        var search_words = data_lowercase.split(" ");
        search_words = search_words.filter(n => n)
        var filtered_data = []
        if (search_words.length === 0) {
            filtered_data = this.state.splittedCRM[0].allCRMCopy
        }
        for (let i = 0; i < this.state.splittedCRM[0].allCRMCopy.length; i++) {
            const element = this.state.splittedCRM[0].allCRMCopy[i];
            var num_of_passed = false
            if (element.role_name !== null) {
                for (let j = 0; j < search_words.length; j++) {
                    const el = search_words[j];
                    // const role = this.state.rolesObj[element.role]
                    if (element.role_name) {
                        const role = element.role_name
                        if (role !== undefined)
                            num_of_passed = role.toLowerCase().includes(el)
                        if (!num_of_passed) {
                            break
                        }
                    }

                }
            }
            if (num_of_passed) {
                filtered_data.push(element)
            }
        }
        if (data.length === 0) {
            localStorage.removeItem("roleSearchKey")
        } else {
            localStorage.setItem("roleSearchKey", data)
        }
        this.setState({ splittedCRM: [{ allCRM: filtered_data, allCRMCopy: this.state.splittedCRM[0].allCRMCopy }] })
    }

    screenSizeCheck() {
        if (window.innerWidth > 1600) {
            return true
        } else {
            return false
        }
    }

    prev6months = () => {
        if (!this.state.selectedStartDate || !this.state.selectedEndDate) {
            this.setState({ selectedStartDate: moment().subtract(2, 'months').startOf('month').format("YYYY-MM-DD"), selectedEndDate: moment().add(6, 'months').endOf('month').format("YYYY-MM-DD") }, () => {
                this.setState({
                    selectedStartDate: moment(this.state.selectedStartDate).subtract(6, 'months').startOf("month").format("YYYY-MM-DD"),
                    selectedEndDate: moment(this.state.selectedEndDate).subtract(6, 'months').endOf("month").format("YYYY-MM-DD")
                }, () => {
                    this.props.getData(this.state.selectedStartDate, this.state.selectedEndDate)
                })
            })
        } else {
            this.setState({
                selectedStartDate: moment(this.state.selectedStartDate).subtract(6, 'months').format("YYYY-MM-DD"),
                selectedEndDate: moment(this.state.selectedEndDate).subtract(6, 'months').endOf("month").format("YYYY-MM-DD")
            }, () => {
                this.props.getData(this.state.selectedStartDate, this.state.selectedEndDate)
            })
        }

    }

    next6months = () => {
        if (!this.state.selectedStartDate || !this.state.selectedEndDate) {
            this.setState({
                selectedStartDate: moment().subtract(2, 'months').startOf('month').format("YYYY-MM-DD"),
                selectedEndDate: moment().add(6, 'months').endOf('month').format("YYYY-MM-DD")
            }, () => {
                this.setState({
                    selectedStartDate: moment(this.state.selectedStartDate).add(6, 'months').startOf("month").format("YYYY-MM-DD"),
                    selectedEndDate: moment(this.state.selectedEndDate).add(6, 'months').endOf('month').format("YYYY-MM-DD")
                }, () => {
                    this.props.getData(this.state.selectedStartDate, this.state.selectedEndDate)
                })
            })
        } else {
            this.setState({
                selectedStartDate: moment(this.state.selectedStartDate).add(6, 'months').startOf("month").format("YYYY-MM-DD"),
                selectedEndDate: moment(this.state.selectedEndDate).add(6, 'months').endOf('month').format("YYYY-MM-DD")
            }, () => {
                this.props.getData(this.state.selectedStartDate, this.state.selectedEndDate)
            })
        }
    }

    handleDateChange1 = (d) => {
        this.setState({ selectedStartDate: moment(d).format('YYYY-MM-DD') })
    }

    handleDateChange2 = (d) => {
        this.setState({ selectedEndDate: moment(d).format('YYYY-MM-DD') })
    }

    showProjects = (projectDetails, roleName) => {
        this.setState({ projectDetails: projectDetails, roleName: roleName }, () => {
            this.setState({ showRoleDialog: true })
        })
    }

    filterProject = (projectDetails, crmName) => {
        for (let index = 0; index < projectDetails.length; index++) {
            const element = projectDetails[index];
            if (element.crm_name === crmName) {
                this.setState({ selectedCRM: element }, () => {
                    this.setState({ unallocatedRolesDialog: true })
                })
            }
        }
    }

    decreaseZoom = () => {
        let zoom = (Number(this.state.zoom) - 0.1).toFixed(1);
        if (zoom < 0.5) return;
        this.setState({ zoom: zoom })
    }

    increaseZoom = () => {
        let zoom = (Number(this.state.zoom) + 0.1).toFixed(1);
        if (zoom > 1.5) return;
        this.setState({ zoom: zoom })
    }

    render() {
        return (
            // <div>{this.state.isLoading ? <Box display="flex" justifyContent="center" alignItems="center" style={{ height: window.innerHeight - 200 }}><LoopCircleLoading /></Box> :
            <div zoom={this.state.zoom}>
                <Box display="flex" width={'100%'} justifyContent='space-between' alignItems='center' margin="10px">
                    <Box display='flex' flexDirection={'row'} alignItems={'center'}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker
                                variant="dialog"
                                openTo="month"
                                views={["month", "year"]}
                                label="Start Month"
                                maxDate={this.state.selectedEndDate ? moment(this.state.selectedEndDate).subtract(1, 'month') : undefined}
                                autoOk={true}
                                value={this.state.selectedStartDate}
                                onChange={this.handleDateChange1}
                                style={{ marginRight: '10px' }}
                            />
                            <DatePicker
                                variant="dialog"
                                openTo="month"
                                views={["month", "year"]}
                                label="End Month"
                                minDate={this.state.selectedStartDate ? moment(this.state.selectedStartDate).add(1, 'month') : undefined}
                                autoOk={true}
                                value={this.state.selectedEndDate}
                                onChange={this.handleDateChange2}
                                style={{ marginRight: '10px' }}
                            />
                        </MuiPickersUtilsProvider>
                        <Button variant="contained" color="primary" size='small'
                            onClick={() => {
                                this.props.getData(this.state.selectedStartDate, this.state.selectedEndDate)
                            }}> Get Report</Button>

                    </Box>
                    <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                        <Box>
                            <Button size='small' variant="outlined" color="primary" onClick={() => this.prev6months()} style={{ marginLeft: "10px" }}>
                                prev 6 months
                            </Button>
                            <Button size='small' variant="outlined" color="primary" onClick={() => this.next6months()} style={{ marginLeft: "10px" }}>
                                next 6 months
                            </Button>
                        </Box>
                        <Box marginLeft={'10px'}>
                            <TextField
                                autoFocus
                                autoComplete='off'
                                size='small'
                                placeholder="Search"
                                id="search"
                                variant='outlined'
                                onChange={(e) => {
                                    this.handleSearchChange(e.target.value)
                                }}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><SearchIcon fontSize='small' /></InputAdornment>,
                                    endAdornment: <InputAdornment position="end">
                                        <IconButton onClick={() => { localStorage.removeItem("searchKey"); this.handleSearchChange('') }}>
                                            <CloseIcon fontSize='small' />
                                        </IconButton>
                                    </InputAdornment>
                                }}
                            />
                        </Box>
                        <ToggleButtonGroup
                            exclusive
                            aria-label="text alignment"
                            style={{ marginLeft: "10px", float: 'right' }}
                            size='small'
                            color='primary'
                        >
                            <ToggleButton value="weeks" aria-label="by week" onClick={this.decreaseZoom} >
                                <ZoomOut />
                            </ToggleButton>
                            <ToggleButton value="months" aria-label="by month" onClick={this.increaseZoom}>
                                <ZoomIn />
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Box>
                </Box>
                <Paper elevation={3} variant={"outlined"}>
                    <div style={{ maxHeight: window.innerHeight - (this.screenSizeCheck() ? 240 : 272), overflowY: 'scroll' }}>
                        <Table stickyHeader style={{ tableLayout: 'fixed', maxWidth: '100px' }}>
                            <TableHead>
                                <TableRow >
                                    <TableCell colSpan={2} rowSpan={2} width={200} align="center" style={{ background: "#F8F8F8", zIndex: 999 }} >
                                        <b>{"Role"}</b>
                                    </TableCell>
                                    {this.state.allMonths.map((month, colIndex) => {
                                        return <TableCell style={{ border: "1px solid rgba(224, 224, 224, 1)" }} key={month.month} colSpan={month.colSpan} align='center' width={month.width}><b>{month.month}</b></TableCell>
                                    })}
                                </TableRow>
                                <TableRow>
                                    {this.state.finalColumns.map((column, rowIndex) => {
                                        return <TableCell style={{ border: "1px solid rgba(224, 224, 224, 1)" }} align="center" width={column.width} ><b>{column.title}</b></TableCell>
                                    })}
                                </TableRow>
                            </TableHead>
                            {this.state.splittedCRM[0].allCRM.length > 0 ?
                                <TableBody>
                                    {this.state.splittedCRM[0].allCRM.map((crm, rowIndex) => {
                                        return <TableRow>
                                            <TableCell colSpan={2} align="center" style={{ background: "#F8F8F8", zIndex: 999, position: 'sticky', left: 0, cursor: 'pointer' }} onClick={() => { this.showProjects(crm.project_details, crm.role_name) }}>
                                                <b>{crm.role_name}</b>
                                            </TableCell>
                                            {crm.weekdata.map((data, colIndex) => {
                                                return <TableCell style={{ border: "1px solid rgba(224, 224, 224, 1)", padding: 0 }} >
                                                    {data.name.map((ele, index) => {
                                                        return <Tooltip key={index} title={<span style={{ fontSize: '0.8rem' }}>{ele ? ele + " - (Qty:" + data.qty[index] + ")" : null}</span>} arrow>
                                                            <div onClick={(e) => { this.filterProject(crm.project_details, ele) }} style={{ cursor: 'pointer', background: data.crm_color_code[index], width: '100%', color: data.crm_color_code[index] ? this.wc_hex_is_light(data.crm_color_code[index]) : "#000" }}>
                                                                {ele ? ele + " - (Qty:" + data.qty[index] + ")" : null}
                                                            </div>
                                                        </Tooltip>
                                                    })}
                                                </TableCell>
                                            })}
                                        </TableRow>
                                    })}
                                </TableBody> : null}
                        </Table>
                    </div>
                </Paper>
                <Dialog fullWidth open={this.state.showRoleDialog} onClose={() => this.setState({ showRoleDialog: false })}>
                    <DialogTitle>{"CRM schedules available for " + this.state.roleName}</DialogTitle>
                    <DialogContent>
                        <Box>
                            <List>
                                {this.state.projectDetails.map((project, index) => {
                                    return <ListItem button key={index} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '10px' }} onClick={() => this.setState({ selectedCRM: project }, () => { this.setState({ unallocatedRolesDialog: true }) })}>
                                        <b>{project.crm_name}</b>
                                    </ListItem>
                                })}
                            </List>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button color='primary' size='small' variant='contained' onClick={() => this.setState({ showRoleDialog: false })}>Close</Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    fullScreen
                    open={this.state.unallocatedRolesDialog}
                    TransitionComponent={Transition}
                    disableEnforceFocus >
                    <AppBar sx={{ position: 'relative' }}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={() => { this.setState({ unallocatedRolesDialog: false }) }} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6">
                                Unallocated Roles
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <DialogContent>
                        <Box marginTop='65px'>
                            <UnallocatedRoles project={this.state.selectedCRM} selectedRoleGroup={this.props.selectedRoleGroup} id={this.state.selectedCRM.crm_id} />
                        </Box>
                    </DialogContent>
                </Dialog>
            </div>
            // }
            // </div>
        )
    }
}

export default withSnackbar(StaffLocationView)
